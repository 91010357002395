import React from 'react';
import './index.scss';
import Ak from './img/ak.png';

function Homepage() {
  return (
    <section className="Homepage">
      <div className="container">
        <img src={ Ak } alt="ak"/>
      </div>
    </section>
  );
}

export default Homepage;
